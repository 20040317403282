import React from "react";
import serviceS1 from "@/images/mountain.png";
import serviceS2 from "@/images/s2.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <h2>Rocks and Metals Trading</h2>
      <p>
      Palm Desert Trading Company specializes in the trade of a diverse range of rocks and metals, catering to various industrial and decorative needs. Our extensive inventory includes high-quality phosphate, which is essential for agriculture and industrial applications, as well as calcium carbonate, widely used in construction and manufacturing. Additionally, we offer unique steel skull rocks, prized for their aesthetic appeal and durability. With a commitment to excellence and customer satisfaction, Palm Desert Trading Company is your trusted source for all types of rocks and metals.
      </p>
      <img src={serviceS1} alt="" />
    </div>
  );
};

export default ServiceDetailsContent;
