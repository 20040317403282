import React, { Fragment } from "react";

const ServiceSidebar = () => {
  return (
    <Fragment>
      <aside className="widget categories">
        <h3 className="widget_title">Services</h3>
        <div className="meipaly_categorie_widget">
          <ul>
            <li>
              <a href="#">Introduction</a>
            </li>
            <li>
              <a href="#">Rocks and Metals Types</a>
            </li>
            <li>
              <a href="#">Rocks and Metals Details</a>
            </li>
          </ul>
        </div>
      </aside>
      <aside className="widget categories">
        <div className="meipaly_services_help">
          <h4>Place Your Order Today</h4>
          <p>
          Elevate your rock and metal trading experience with unmatched efficiency and expertise. Embrace the value of sustainable practices, meticulous sorting, and our precision-driven professional service. Partner with us today to maximize your returns while minimizing waste. Discover the future of rock and metal trading!
          </p>
        </div>
      </aside>
    </Fragment>
  );
};

export default ServiceSidebar;
