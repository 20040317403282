import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "@/components/section-title";
// import { FeatureTabData } from "@/data";
import featureTab01 from "@/images/phosphate.png";
import featureTab02 from "@/images/calcium-slide.png";
import featureTab03 from "@/images/skull-slider.png";
import featureTab04 from "@/images/Aluminum-slide.png";

const FeatureTabOne = () => {
  const [active, setActive] = useState(1);
  // const { sectionContent, posts } = FeatureTabData;

  const FeatureTabData = {
    sectionContent: {
      title: "Rocks and Metals Types",
      subTitle: "Explore our",
    },
    posts: [
      {
        title: "Phosphate Rock",
        content:
          "Palm Desert Trading Company specializes in the trading of phosphate rock, a vital resource for the agricultural industry. With a focus on sourcing high-quality phosphate rock from reputable suppliers, the company plays a crucial role in ensuring a steady supply of this essential mineral for fertilizer production. By leveraging its extensive network and expertise in international trade, Palm Desert Trading Company facilitates the movement of phosphate rock across global markets, helping to meet the growing demand for sustainable agricultural practices. Committed to quality and customer satisfaction, the company positions itself as a reliable partner for businesses seeking to enhance their fertilizer offerings and support crop production worldwide.",
        image: featureTab01,
      },
      {
        title: "Calcium Carbonate Rock",
        content:
          "Calcium carbonate rock, commonly known as limestone, is a naturally occurring mineral composed primarily of calcium carbonate (CaCO₃). This versatile rock is extensively used in various industries, including construction, agriculture, and manufacturing. Palm Desert Trading Company plays a significant role in the trading of high-quality calcium carbonate rock, ensuring a reliable supply for its clients. In construction, it serves as a key ingredient in cement and is utilized for aggregate in concrete. In agriculture, calcium carbonate rock is valued for its ability to improve soil quality and provide essential nutrients to crops. Additionally, it is used in the production of lime, which has numerous applications in environmental and industrial processes. With its abundance and multifunctionality, calcium carbonate rock, facilitated by Palm Desert Trading, plays a vital role in supporting sustainable practices across diverse sectors.",
        image: featureTab02,
      },
      {
        title: "Steel Skull Rock",
        content:
          "Steel rocks refer to a specific type of rock used in construction and industrial applications, often characterized by their durability and strength. These rocks can include various types of aggregate materials, such as steel slag, which is a byproduct of steel manufacturing. Steel slag is commonly utilized in road construction, concrete production, and as a base material due to its high density and ability to withstand heavy loads. Notably, when steel rocks are exposed to a magnetic field, they can become magnetized and may be attracted to magnets, making them interesting from both a practical and scientific perspective. Additionally, the term may also refer to artistic representations or sculptures made from steel that mimic the appearance of rock formations. Overall, steel rocks play a crucial role in both construction and creative projects, highlighting the versatility of materials derived from steel production.",
        image: featureTab03,
      },
      {
        title: "Aluminum Dross",
        content:
          "Aluminum dross is a byproduct generated during the aluminum smelting and refining processes. It consists of aluminum oxide, aluminum metal, and other impurities that rise to the surface during the melting of aluminum. Dross can vary in composition, typically containing around 30-70% aluminum, depending on the process and materials used. Aluminum dross is often treated to recover the aluminum content, which can be reused in production, reducing waste and promoting sustainability. The remaining material, primarily aluminum oxide, can be utilized in various applications, including construction materials, ceramics, and as an abrasive. Proper handling and recycling of aluminum dross are important to minimize environmental impact and enhance resource efficiency.",
        image: featureTab04,
      },
    ],
  };

  return (
    <section id="why" className="majdoolchooseUs">
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <SectionTitle data={FeatureTabData.sectionContent} />
          </Col>
        </Row>
        <div id="tabs">
          <Row>
            <Col lg={12}>
              <ul className="majdoolchooseUs_title">
                {FeatureTabData.posts.map(({ title }, index) => (
                  <li
                    key={index}
                    className={`${active === index ? "active" : " "}`}
                  >
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setActive(index);
                      }}
                    >
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <div className="tab-content">
            {FeatureTabData.posts.map((post, index) => {
              return index === active ? (
                <div
                  className="tab-pane fade show active animated fadeIn"
                  id={`tes_tab_${index}`}
                  key={index}
                >
                  <Row
                    className={`${
                      0 === index % 2 ? " " : "flex-lg-row-reverse"
                    }`}
                  >
                    <Col lg={7}>
                      <div className="wh_choose">
                        <p>{post.content}</p>
                        {/* <ul>
                          {post.lists.map(({ item }, index) => (
                            <li key={index}>
                              <i className="fa fa-check-square"></i> {item}
                            </li>
                          ))}
                        </ul> */}
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="chose_img">
                        <img src={post.image} alt={`chose_img-${index}`} />
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null;
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FeatureTabOne;
